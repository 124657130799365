import React, { ReactNode, createContext, useMemo, useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps, NavLinkContainerContext } from '../internal/interfaces';
import { Colors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import { useMediaQuery } from '../useMediaQuery';
import { useBreakpoints } from '../useBreakpoints';
import { Surface, SurfaceElevation } from '../Surface';
import './TopNavigation.scss';

export type TopNavigationPosition =
  | 'absolute'
  | 'fixed'
  | 'relative'
  | 'static'
  | 'sticky';

export interface ITopNavigationContext {
  color: Colors;
  isLargeOrHigher: boolean;
}

export const TopNavigationContext = createContext<ITopNavigationContext | null>(
  null
);

interface ITopNavigationContent extends CommonProps {
  /**
   * Specify the content to render inside of the component.
   */
  children: ReactNode;
  /**
   * Specify where to render the content within the TopNavigation.
   */
  section: 'start' | 'middle' | 'end';
  /**
   * If `true`, does not render children. Useful when pairing this component with a Drawer for responsive behavior.
   */
  hideContent?: boolean;
}

export interface TopNavigationProps
  extends React.ComponentPropsWithoutRef<'header'>,
    CommonProps {
  /**
   * Specify the variant of the TopNavigation.
   * @default 'primary'
   */
  color?: Colors;
  /**
   * Specify the behavior of the position of the TopNavigation.
   * @default 'fixed'
   */
  position?: TopNavigationPosition;
  /**
   * Specify the elevation of the TopNavigation.
   * @default 1
   */
  elevation?: SurfaceElevation;
  /**
   * Specify the content to render inside of the TopNavigation.
   */
  children: ReactNode;
}

export const TopNavigationContent: React.VFC<ITopNavigationContent> = ({
  children,
  section,
  hideContent = false,
  id: idProp,
}) => {
  const id = useId(idProp);
  const isMiddle = section === 'middle';
  const isEnd = section === 'end';

  return (
    <div
      id={id}
      className={clsx([
        'top-navigation-content-base',
        {
          'top-navigation-content-grow': isMiddle,
          'top-navigation-content-end': isEnd,
        },
      ])}
    >
      {hideContent ? null : children}
    </div>
  );
};

export const TopNavigationContentShift: React.VFC = () => {
  const [cssPrefix] = useCSSPrefix();
  const { isLargeOrHigher } = useContext(TopNavigationContext) || {};

  return (
    <div
      className={clsx([
        `${cssPrefix}-top-navigation-filler`,
        {
          'top-navigation-filler-large': isLargeOrHigher,
        },
      ])}
    />
  );
};

export const TopNavigation = React.forwardRef<
  HTMLDivElement,
  TopNavigationProps
>(
  (
    {
      color = 'primary',
      position = 'fixed',
      elevation = 1,
      className,
      children,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const breakpoints = useBreakpoints();
    const isMediumOnly = useMediaQuery(breakpoints.only('md'));
    const isLargeOrHigher = useMediaQuery(breakpoints.up('lg'));

    const absoluteOrFixed = position === 'absolute' || position === 'fixed';

    const value = useMemo(
      () => ({
        color,
        isLargeOrHigher,
      }),
      [color, isLargeOrHigher]
    );

    return (
      <TopNavigationContext.Provider value={value}>
        <NavLinkContainerContext.Provider value="topnavigation">
          <Surface
            ref={ref}
            id={id}
            as="header"
            radii="none"
            elevation={elevation}
            className={clsx([
              `${cssPrefix}-top-navigation`,
              {
                'top-navigation-padding-more': isMediumOnly,
                'top-navigation-padding-most': isLargeOrHigher,
              },
              `top-navigation-position-${position}`,
              `top-navigation-color-${color}`,
              className,
            ])}
            {...rest}
          >
            <div className="top-navigation-content-base top-navigation-content-grow">
              {children}
            </div>
          </Surface>

          {absoluteOrFixed && <TopNavigationContentShift />}
        </NavLinkContainerContext.Provider>
      </TopNavigationContext.Provider>
    );
  }
);
