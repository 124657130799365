import React, { ComponentPropsWithoutRef, forwardRef, useContext } from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import { Sizes, LogoColors, Colors } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import { TopNavigationContext } from '../TopNavigation';
import { SideNavigationContext } from '../SideNavigation';
import * as Logos from './logos';
import './Logo.scss';

export const LogoMapping = {
  symbol: Logos.Symbol,
  script: Logos.Script,
  symbolInCircle: Logos.SymbolInCircle,
  symbolInSquare: Logos.SymbolInSquare,
  symbolWithDots: Logos.SymbolWithDots,
};

export type LogoType = keyof typeof LogoMapping;
export interface LogoProps
  extends ComponentPropsWithoutRef<'svg'>,
    CommonProps {
  /**
   * Specify the logo to render from the following list:
   */
  logo: LogoType;
  /**
   * By default the size will be inherited from the current font size. Optionally, specify a size from the following list:
   * @default 'sm'
   */
  size?: Sizes;
  /**
   * Specify a color for the component.
   * @default 'primary'
   */
  color?: LogoColors;
}

function getColorToUse(incomingColor?: Colors): LogoColors | undefined {
  if (incomingColor === 'secondary') return 'tertiary';
  return 'primary';
}

export const Logo = forwardRef<SVGSVGElement, LogoProps>(
  ({ logo, className, id: idProp, size = 'sm', color, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const { color: topNavColor, isLargeOrHigher } =
      useContext(TopNavigationContext) || {};
    const { color: sideNavColor } = useContext(SideNavigationContext) || {};

    const colorToUse =
      color ?? getColorToUse(topNavColor || sideNavColor) ?? 'primary';

    let sizeToUse: string;

    switch (isLargeOrHigher) {
      case true:
        sizeToUse = 'md';
        break;
      case false:
        sizeToUse = 'sm';
        break;
      default:
        sizeToUse = size;
    }

    const Comp: React.VFC<React.SVGProps<SVGSVGElement>> = LogoMapping[logo];

    return (
      <Comp
        {...props}
        id={id}
        ref={ref}
        className={clsx([
          `${cssPrefix}-logo`,
          logo === 'script' && `logo-script`,
          `logo-${sizeToUse}`,
          `logo-color-${colorToUse}`,
          className,
        ])}
      />
    );
  }
);
