/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';

export default function useMediaQuery(query: string) {
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

  const [match, setMatch] = useState(
    supportMatchMedia && window.matchMedia(query).matches
  );

  useEffect(() => {
    if (!supportMatchMedia) {
      setMatch(false);
      return;
    }

    const queryList = matchMedia(query);

    const updateMatch = () => {
      setMatch(queryList.matches);
    };

    queryList.addListener(updateMatch);

    return () => {
      queryList.removeListener(updateMatch);
    };
  }, [query, supportMatchMedia]);

  return match;
}
